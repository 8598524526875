<template>
  <div class="dashboard-container">
    <div class="header-container flex flex-between">
      <div class="left-box"></div>
      <div class="right-box flex flex-start">
        <div class="clock">
          <div class="hour-hand"></div>
          <div class="minute-hand"></div>
        </div>
        <div class="date">{{ headDate }}</div>
      </div>
    </div>
    <div class="container-box flex flex-between">
      <div class="left-content flex flex-dr flex-center">
        <div class="data-box course-complete-box">
          <div class="title-box">课程完成情况</div>
          <div class="content-box">
            <div class="course-li flex flex-between" v-for="item in experimentDataList">
              <div class="avatar-box">
                <img :src="item.experiment.icon"/>
              </div>
              <div class="numbers">
                <div class="number-li">
                  <div class="title flex flex-between">
                    <span class="title-name">完成人次</span>
                    <span class="number">{{ item.data.completeNum }}/{{ item.data.scheduleNum }}</span>
                  </div>
                  <div class="number-bar">
                    <div class="number-bar-bg"></div>
                    <div class="number-bar-show"
                         :style="'width:'+300*(item.data.completeNum/item.data.scheduleNum)+'px'"></div>
                  </div>
                </div>
                <div class="number-li">
                  <div class="title flex flex-between">
                    <span class="title-name">平均得分</span>
                    <span class="number">{{ item.data.completeAvgScore.toFixed(0) }}分</span>
                  </div>
                  <div class="number-bar">
                    <div class="number-bar-bg"></div>
                    <div class="number-bar-show" :style="'width:'+300*(item.data.completeAvgScore/100)+'px'"></div>
                  </div>
                </div>
                <div class="number-li">
                  <div class="title flex flex-between">
                    <span class="title-name">完成率</span>
                    <span class="number">{{ item.data.completeRate }}%</span>
                  </div>
                  <div class="number-bar">
                    <div class="number-bar-bg"></div>
                    <div class="number-bar-show"
                         :style="'width:'+300*(item.data.completeRate/100)+'px'"></div>
                  </div>
                </div>
                <div class="number-li">
                  <div class="title flex flex-between">
                    <span class="title-name">平均耗时</span>
                    <span class="number">{{ (item.data.completeAvgUsedTime / 60).toFixed(0) }}分钟</span>
                  </div>
                  <div class="number-bar">
                    <div class="number-bar-bg"></div>
                    <div class="number-bar-show"
                         :style="'width:'+300*(item.data.completeAvgUsedTime /3600 )+'px'"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data-box course-use-time-box">
          <div class="title-box">学习用时情况</div>
          <div class="content-box flex flex-around">
            <div class="cylinder-box cylinder-box1 flex flex-dr flex-center">
              <div class="number">{{ (totalUsedTimeInfo.completeTotalUsedTime / 2700).toFixed(0) }}学时</div>
              <div class="cylinder cylinder1 "></div>
              <div class="title">总学习时长</div>
            </div>
            <div class="cylinder-box cylinder-box2 flex flex-dr flex-center">
              <div class="number">
                {{
                  totalUsedTimeInfo.completeNum === 0 ? 0 : (totalUsedTimeInfo.completeTotalUsedTime / 2700 / totalUsedTimeInfo.completeNum).toFixed(2)
                }}学时
              </div>
              <div class="cylinder cylinder2"></div>
              <div class="title">人均学习时长</div>
            </div>
            <div class="cylinder-box cylinder-box3 flex flex-dr flex-center">
              <div class="number">
                {{
                  totalUsedTimeInfo.completeNum === 0 ? 0 : (totalUsedTimeInfo.scoreNum / totalUsedTimeInfo.completeNum).toFixed(2)
                }}次
              </div>
              <div class="cylinder cylinder3"></div>
              <div class="title">人均实验次数</div>
            </div>
          </div>
        </div>
      </div>
      <div class="center-content">
        <div class="title-banner">
          <img src="@/assets/dashboard/index_title_banner.png" alt="" class="title-banner">
          <span class="text">综合数据</span>
        </div>
        <div class="class-box flex flex-between">
          <div class="text">{{ clazzName }}</div>
          <!--          <img src="@/assets/dashboard/class_down_icon.png" alt="">-->
        </div>
        <el-select v-model="clazzId" class="select-input" @change="classChange">
          <el-option v-for="(item,index) in clazzList.data" :value="item.value" :key="index"
                     :label="item.label"></el-option>
        </el-select>
        <div class="data-qiu-box">
          <div class="qiu qiu-1 flex flex-dr flex-center">
            <div class="title">完成实验总人次</div>
            <div class="number">{{ dashboard_index_1_result['completeNum'] }}</div>
          </div>
          <div class="qiu qiu-2 flex flex-dr flex-center">
            <div class="title">实验完成率</div>
            <div class="number">{{ dashboard_index_1_result['completeRate'] }}%</div>
          </div>
          <div class="qiu qiu-3 flex flex-dr flex-center">
            <div class="title">安排实验总人次</div>
            <div class="number">{{ dashboard_index_1_result['scheduleNum'] }}</div>
          </div>
          <div class="qiu qiu-4 flex flex-dr flex-center">
            <div class="title">实验质量</div>
            <div class="number">{{ dashboard_index_1_result['qualityText'] }}</div>
          </div>
        </div>
        <div class="sprite-box">
        </div>
        <div class="go-class-box" @click="clickGoToClassBtn()">
          <img src="@/assets/dashboard/go_to_class_btn_background.png" alt="">
          <span class="text">点我查看班级详情吧</span>
        </div>
        <div class="company-box">技术服务：成都智云鸿道信息技术有限公司</div>
      </div>
      <div class="right-content flex flex-dr flex-center">
        <div class="data-box study-action-box">
          <div class="title-box">学习行为数据</div>
          <div class="content-box flex flex-dr flex-center">
            <div class="number-box flex flex-between">
              <div class="number-li flex flex-dr flex-center">
                <div class="number">{{ this.studyAction.totalScheduleCount }}</div>
                <!--                <div class="number">{{ dashboard_index_1_result['scheduleNum'] }}</div>-->
                <div class="title flex flex-between">
                  <span class="square"></span>
                  <span class="text">安排人次</span>
                </div>
              </div>
              <div class="number-li flex flex-dr flex-center">
                <!--                <div class="number">{{ dashboard_index_1_result['studyNum'] }}</div>-->
                <div class="number">{{ this.studyAction.totalScoreCount }}</div>
                <div class="title flex flex-between">
                  <span class="square"></span>
                  <span class="text">学习人次</span>
                </div>
              </div>
              <!--              <div class="number-li flex flex-dr flex-center">-->
              <!--                <div class="number">{{ dashboard_index_1_result['completeNum'] }}</div>-->
              <!--                <div class="title flex flex-between">-->
              <!--                  <span class="square"></span>-->
              <!--                  <span class="text">完成人次</span>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="chart-box" id="action-chart"></div>
          </div>
        </div>
        <div class="data-box score-chart-box">
          <div class="title-box">优良占比数据</div>
          <div class="content-box flex flex-around">
            <div class="chart-box" id="score-chart"></div>
          </div>
        </div>
        <div class="right-date-box flex flex-between">
          <div class="left-box">数据时段:</div>
          <div class="right-box">
            <div class="btn-box flex flex-between">
              <div :class="date_type===1?'btn active':'btn'" @click="clickDateBtn(1)">本月度</div>
              <div :class="date_type===2?'btn active':'btn'" @click="clickDateBtn(2)">本季度</div>
              <div :class="date_type===3?'btn active':'btn'" @click="clickDateBtn(3)">本年度</div>
            </div>
            <div class="select-box flex flex-between">
              <img src="@/assets/dashboard/date_box_icon.png" alt="" class="icon">
              <span class="start-date">{{ start_date_text }}</span>
              <span class="text">至</span>
              <span class="end-date">{{ end_date_text }}</span>
              <el-date-picker
                  class="date_picker"
                  v-model="dateRange"
                  type="daterange"
                  @change="dateChange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00','23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--全屏按钮弹出框-->
    <div class="my-dialog-container" v-show="showFullDialog">
      <div class="mask"></div>
      <div class="dialog-box" style="background-color: transparent;width: 100%;top:50%">
        <div class="flex flex-center">
          <el-button id="fullScreenBtn" type="danger" size="">全屏查看</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {StatisticModel} from "@/model/exp/StatisticModel";
import {CommonModel} from "@/model/CommonModel";
import {date_format, find_obj_from_arr_by_id} from "@/utils/common";
import {msg_err} from "@/utils/ele_component";

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {BarChart, LineChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  DataZoomComponent,
  LineChart
]);

export default {
  name: "v1Index",
  data() {
    return {
      schoolId: "59977128740917248",
      clazzList: {
        data: [],
        object: {}
      },
      experimentList: {},
      experimentDataList: [],
      totalUsedTimeInfo: {
        completeTotalUsedTime: 0,// 总实验用时
        scoreNum: 0,// 总分数次数
        completeNum: 0,// 总完成次数
      },
      clazzId: null,
      clazzName: "选择班级",
      dateRange: [],
      date_type: 0,
      start_time: null,
      end_time: null,
      start_date_text: "开始日期",
      end_date_text: "结束日期",
      headDate: "",
      headDateTimeId: "",
      dashboard_index_1_result: {},
      showFullDialog: false,
      studyAction:{
        totalScoreCount:0,// 总学习次数
        totalScheduleCount:0,// 总安排次数
      },

    }
  },
  async mounted() {
    // 顶部每秒日期更新
    this.headDateTimeId = setInterval(() => {
      this.formatHeadDate()
    }, 1000)

    // 如果不是全屏状态就弹出全屏按钮
    if (!document.fullscreenElement) {
      this.showFullDialog = true
    }
    let $this = this;
    document.getElementById("fullScreenBtn").onclick = function () {
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      }
      //兼容火狐
      if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
      //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
      //兼容IE,只能写msRequestFullscreen
      if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      setTimeout(() => {
        $this.showFullDialog = false
      }, 1000)
    }
    window.onresize = function () {
      if ($this.showFullDialog === false) {
        $this.showFullDialog = true
      }
    }
    // 监听班级选择框点击
    window.$(".class-box").click(() => {
      window.$(".select-input").click()
    })
    // 监听日期选择框点击
    window.$(".select-box .start-date,.select-box .end-date").click(() => {
      window.$(".date_picker").click()
    })
    // 获取教学班级列表
    this.getClazzList()
    // 获取所有实验列表
    await this.getExperimentList()
    // 开始获取数据
    this.getData()
  },
  beforeDestroy() {
    //  组件销毁时清除定时器
    clearInterval(this.headDateTimeId)
    sessionStorage.setItem("fullScreen", "false");
  },
  methods: {
    // 点击查看学生详情按钮
    clickGoToClassBtn() {
      this.$router.push("/dashboard/v1/class?clazzId=" + this.clazzId + "&schoolId=" +
          this.schoolId + "&clazzName=" + this.clazzName + "&start_time=" + this.start_time + "&end_time=" + this.end_time)
    },
    // 获取数据
    async getData() {
      if (this.clazzId === "") {
        this.clazzId = null
      }
      // 重置数据
      this.experimentDataList = []
      this.totalUsedTimeInfo = {
        completeTotalUsedTime: 0,// 总实验用时
        scoreNum: 0,// 总分数次数
        completeNum: 0,// 总完成次数
      }
      this.get_dashboard_index_1()
      this.getExperimentData()
      this.getStudyActionDate()
    },
    // 班级选择变动
    async classChange(v) {
      this.clazzName = this.clazzList.object[v]
      if(this.clazzId){
        await this.getDashboardClazzExperiments()
      }else{
        await this.getExperimentList()
      }
      this.getData()
    },
    // 获取本班级安排的所有实验id列表
    async getDashboardClazzExperiments() {
      this.experimentList = await StatisticModel.getDashboardClazzExperiments(this.clazzId)
    },
    // 日期选择变动
    dateChange(v) {
      this.start_time = v[0]
      this.end_time = v[1]
      this.start_date_text = v[0].slice(0, 10)
      this.end_date_text = v[1].slice(0, 10)
      this.getData()
    },
    // 点击快速选择日期范围按钮
    clickDateBtn(type) {
      this.date_type = type
      let time_start = ""
      let time_end = date_format(new Date().setHours(23, 59, 59, 0), "yyyy-MM-dd HH:mm:ss")
      switch (type) {
        case 1:// 本月
          let startOfMonth = new Date();
          startOfMonth.setDate(1);
          startOfMonth.setHours(0, 0, 0, 0);
          time_start = date_format(startOfMonth, "yyyy-MM-dd HH:mm:ss")
          break;
        case 2:// 本季度
          const time = {};
          const date = new Date();
          date.setHours(0, 0, 0, 0);
          const month = date.getMonth();
          const quarter = Math.floor(month / 3) + 1; // 从1开始，到4
          const startMonth = (quarter * 3) - 2;
          date.setMonth(startMonth - 1);
          const startDay = date.setDate(1);
          time_start = date_format(startDay, "yyyy-MM-dd HH:mm:ss")
          break;
        case 3:// 本年度
          let firstDay = new Date();
          firstDay.setHours(0, 0, 0, 0);
          firstDay.setDate(1);
          firstDay.setMonth(0);
          time_start = date_format(firstDay, "yyyy-MM-dd HH:mm:ss")
          break;
      }
      this.start_time = time_start;
      this.end_time = time_end;
      this.dateRange = []
      this.start_date_text = time_start.slice(0, 10)
      this.end_date_text = time_end.slice(0, 10)
      this.getData()
    },
    // 获取统计结果1-中间气泡整体数据
    async get_dashboard_index_1() {
      let result = await StatisticModel.dashboard_index_1(this.schoolId, this.clazzId, this.start_time, this.end_time, null)
      if (result) {
        if (result.scheduleNum === 0) {
          result.completeRate = 0;
        } else {
          result.completeRate = (result.completeNum / result.scheduleNum).toFixed(2) * 100;
        }
        result.qualityText = "良好"
        if (result.completeAvgScore >= 90) {
          result.qualityText = "优秀"
        }
        if (result.completeAvgScore <= 80) {
          result.qualityText = "及格"
        }
        this.dashboard_index_1_result = result
      }
    },
    // 获取所有教学班列表
    async getClazzList() {
      let clazzList = await StatisticModel.dashboard_index_all_clazz_list(this.schoolId)
      if (clazzList) {
        let filterList = CommonModel.generateListFilterOptions("name", "id", clazzList, true);
        let clazzListTemp = {
          data: filterList[0],
          object: filterList[1]
        }
        this.clazzList = clazzListTemp
      }
    },
    // 获取所有实验列表
    async getExperimentList() {
      let experimentList = await StatisticModel.getOneSchoolExperimentInfoList(this.schoolId)
      if (experimentList) {
        this.experimentList = experimentList
      }
      // todo 注意一个学校有多个授权学院，学院不同实验不同的问题，要修改整体设计，这次先用学校
      // let experimentList = (await ExperimentModel.getTeacherExperimentList(this.userInfo))
      // let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, true)
      // this.$set(this.experimentList, "data", generateListFilter1[0])
      // this.$set(this.experimentList, "dataObject", generateListFilter1[1])
      // this.$set(this.experimentList, "dataOrigin", experimentList)
    },
    // 获取某个实验的数据
    async getExperimentData() {
      let scoreChartData = []
      for (let i = 0; i < this.experimentList.length; i++) {
        let experiment = this.experimentList[i]
        let experimentId = experiment.id
        let result = await StatisticModel.dashboard_index_1(this.schoolId, this.clazzId, this.start_time, this.end_time, experimentId)
        if (result) {
          if (result.scheduleNum === 0) {
            result.completeRate = 0;
          } else {
            result.completeRate = (result.completeNum / result.scheduleNum).toFixed(2) * 100;
          }
          // 获取每个实验的实验用时数据
          let scoreResult = await StatisticModel.dashboard_index_2(this.schoolId, this.clazzId, this.start_time, this.end_time, experimentId)
          result.completeAvgUsedTime = scoreResult.completeAvgUsedTime // 给单个课程赋值
          this.$set(this.totalUsedTimeInfo, "completeTotalUsedTime", this.totalUsedTimeInfo.completeTotalUsedTime + scoreResult.completeTotalUsedTime) // 叠加总学习用时
          this.$set(this.totalUsedTimeInfo, "scoreNum", this.totalUsedTimeInfo.scoreNum + scoreResult.scoreNum) // 叠加总分数次数
          this.$set(this.totalUsedTimeInfo, "completeNum", this.totalUsedTimeInfo.completeNum + result.completeNum) // 叠加总完成次数
          // 加入实验列表
          this.experimentDataList.push({
            experiment: experiment,
            data: result
          })
          // 加入分数占比数据
          let experimentName = (find_obj_from_arr_by_id("id", experimentId, this.experimentList))[1]["name"]
          scoreChartData.push([experimentName, scoreResult.perfectScorePercent.toFixed(2) * 100, scoreResult.goodScorePercent.toFixed(2) * 100, scoreResult.passScorePercent.toFixed(2) * 100])
        }
      }
      this.drawScoreChart(scoreChartData)
    },
    // 格式化顶部日期
    formatHeadDate() {
      let date = new Date();
      const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const weekday = weekdays[date.getDay()];
      const hour = date.getHours();
      const minute = date.getMinutes();
      // 使用模板字符串拼接出格式化后的日期时间字符串
      this.headDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${weekday} ${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
    },
    // 优良占比数据图表
    drawScoreChart(experimentData) {
      let source = [
        ['product', '优秀', '良好', '及格'],
      ];
      source = source.concat(experimentData)
      //console.log(source)
      let chartDom = document.getElementById('score-chart');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        legend: {
          textStyle: {
            color: 'rgb(255,255,255,0.9)'
          }
        },
        tooltip: {
          enterable: true,
          trigger: 'axis'
        },
        color: ['#4d9af2', '#f2b94b', '#87dde1'],
        textStyle: {
          fontSize: 15,
          color: '#6c7fba',
        },
        dataset: {
          source
        },
        dataZoom: [
          {
            type: "slider", //隐藏或显示（true）组件
            show: true,
            backgroundColor: "rgb(19, 63, 100)", // 组件的背景颜色。
            fillerColor: "rgb(16, 171, 198)", // 选中范围的填充颜色。
            borderColor: "rgb(19, 63, 100)", // 边框颜色
            showDetail: true, //是否显示detail，即拖拽时候显示详细数值信息
            startValue: 0,
            endValue: 5,
            filterMode: "empty",
            width: "100%", //滚动条高度
            height: 15, //滚动条显示位置
            left: "center",
            zoomLoxk: true, // 是否锁定选择区域（或叫做数据窗口）的大小
            handleSize: 0, //控制手柄的尺寸
            bottom: 3, // dataZoom-slider组件离容器下侧的距离
          },
          {
            //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
            type: "inside",
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
        ],

        xAxis: {type: 'category'},
        yAxis: {axisLabel: {formatter: '{value}%'}},
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{type: 'bar'}, {type: 'bar'}, {type: 'bar'}],
      };

      option && myChart.setOption(option);
    },
    // 获取学习行为数据
    async getStudyActionDate() {
      let startTime = ""
      let endTime = ""
      // if (this.dateRange.length === 0) { // 如果没有选择，就生成最近一年的数据
      //   // 获取明天的日期
      //   let today = new Date();
      //   today.setHours(0, 0, 0, 0);
      //
      //   // 一年前的日期
      //   let oneYearAgo = new Date()
      //   // oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      //   oneYearAgo.setMonth(oneYearAgo.getMonth() - 1);
      //   oneYearAgo.setHours(0, 0, 0, 0);
      //   startTime = date_format(oneYearAgo, "yyyy-MM-dd HH:mm:ss")
      //   endTime = date_format(today, "yyyy-MM-dd HH:mm:ss")
      // }
      let dayResult = {}
      // dayResult = await StatisticModel.dashboard_index_3(this.schoolId, null, "2023-04-20 00:00:00", "2023-04-30 00:00:00")

      if (!this.start_time) {// 如果未选择时间，就默认获取本月度的数据
        // 获取本月的开始日期
        let startOfMonth = new Date();
        startOfMonth.setDate(1);
        startOfMonth.setHours(0, 0, 0, 0);
        // 获取当前日期
        let endOfMonth = new Date();
        // 格式化为 yyyy-mm-dd
        let formattedStart = date_format(startOfMonth, "yyyy-MM-dd HH:mm:ss")
        let formattedEnd = date_format(endOfMonth, "yyyy-MM-dd HH:mm:ss")
        dayResult = await StatisticModel.dashboard_index_3(this.schoolId, this.clazzId, formattedStart, formattedEnd)
      } else {
        dayResult = await StatisticModel.dashboard_index_3(this.schoolId, this.clazzId, this.start_time, this.end_time)
      }
      let source = [
        ['product', '安排人次', '学习人次'],
      ];
      this.studyAction.totalScoreCount = 0; // 学习行为数据，总学习人数置0
      this.studyAction.totalScheduleCount = 0; // 学习行为数据，总安排人数置0
      for (let day in dayResult) {
        if (dayResult.hasOwnProperty(day)) {
          source.push([day, dayResult[day]["scheduleCount"], dayResult[day]["scoreCount"]])
          this.studyAction.totalScoreCount += dayResult[day]["scoreCount"] // 记录总学习次数
          this.studyAction.totalScheduleCount += dayResult[day]["scheduleCount"] // 记录总学习次数
        }
      }
      let chartDom = document.getElementById('action-chart');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          text: ''
        },
        legend: {
          textStyle: {
            color: 'rgb(255,255,255,0.9)'
          },
          show: false,
        },
        tooltip: {
          enterable: true,
          trigger: 'axis'
        },
        color: ['#83d3b8', '#e19ff4'],
        textStyle: {
          fontSize: 15,
          color: '#6c7fba',
          show: false
        },
        dataset: {
          source
        },
        dataZoom: [
          {
            type: "slider", //隐藏或显示（true）组件
            show: true,
            backgroundColor: "rgb(19, 63, 100)", // 组件的背景颜色。
            fillerColor: "rgb(16, 171, 198)", // 选中范围的填充颜色。
            borderColor: "rgb(19, 63, 100)", // 边框颜色
            showDetail: true, //是否显示detail，即拖拽时候显示详细数值信息
            startValue: 0,
            endValue: 5,
            filterMode: "empty",
            width: "100%", //滚动条高度
            height: 15, //滚动条显示位置
            left: "center",
            zoomLoxk: true, // 是否锁定选择区域（或叫做数据窗口）的大小
            handleSize: 0, //控制手柄的尺寸
            bottom: 3, // dataZoom-slider组件离容器下侧的距离
          },
          {
            //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
            type: "inside",
            zoomOnMouseWheel: false, //滚轮是否触发缩放
            moveOnMouseMove: true, //鼠标滚轮触发滚动
            moveOnMouseWheel: true,
          },
        ],

        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#3e4148',
                width: 1,//这里是为了突出显示加上的
              }
            },
            type: 'category',
            boundaryGap: false,
          }
        ],
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                color: '#21242b',
              }
            },
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#43484e',
                width: 0,//这里是为了突出显示加上的
              }
            }
          }
        ],
        series: [
          {
            type: 'line',
            symbol: 'none',
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#83d3b8'
                }
              }
            },
          },
          {
            type: 'line',
            symbol: 'none',
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#e19ff4'
                }
              }
            },
          }
        ],
      };

      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

div.dashboard-container {
  background-image: url('../../assets/dashboard/background.png');
  background-position: center 85%;
  height: 100vh;
  width: 100%;
  font-family: MicrosoftYaHei;
  color: #2EB8FF;
}

// 顶部
div.header-container {
  width: 100%;
  height: 113px;
  background-image: url('../../assets/dashboard/head_title.png');
  background-position: center;

  div.right-box {
    margin-bottom: 40px;
    margin-right: 20px;

    .clock {
      width: 25px;
      height: 25px;
      border: 2px solid #2EB8FF;
      border-radius: 50%;
      position: relative;
      margin-right: 10px;
    }

    .hour-hand {
      width: 2px;
      height: 6px;
      background-color: #2EB8FF;
      position: absolute;
      top: 25%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(0deg);
    }

    .minute-hand {
      width: 2px;
      height: 7px;
      background-color: #2EB8FF;
      position: absolute;
      top: 19%;
      left: 50%;
      transform-origin: bottom center;
      transform: translateX(-50%) rotate(140deg);
    }

    .date {
      font-size: 18px;
      color: #2EB8FF;
    }
  }
}

// 中间内容
div.container-box {
  margin-top: -40px;
  padding: 0px 30px;
  // 通用数据展示框
  .data-box {
    width: 520px;

    div.title-box {
      width: 520px;
      height: 42px;
      background-image: url('../../assets/dashboard/box_title_background.png');
      background-position: center;
      line-height: 42px;
      padding-left: 60px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 1.5px;
    }

    div.content-box {
      margin-left: 6px;
      width: 513px;
      height: 550px;
      background-image: url('../../assets/dashboard/box_corner_background.png');
      background-position: center;
    }
  }

  // 课程完成情况
  .course-complete-box {
    .content-box {
      height: 540px !important;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        background-color: #32747c;
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #75fbfd;
      }
    }

    .course-li {
      padding: 10px 20px;


      .avatar-box {
        width: 153px;
        height: 146px;
        padding: 15px 14px 15px 14px;
        background-image: url('../../assets/dashboard/pic_kuang.png');
        background-position: center;

        img {
          width: 125px;
          height: 120px;
        }
      }

      .numbers {
        .number-li {
          width: 300px;
          height: 40px;
          color: #fff;
          font-size: 16px;

          .title {
            margin-bottom: 2px;
          }

          .number-bar {
            position: relative;

            .number-bar-bg {
              background-color: #1f222f;
              width: 300px;
              height: 15px;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 0;
            }

            .number-bar-show {
              background-color: #4993ce;
              width: 0px;
              height: 15px;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 1;
            }
          }
        }
      }
    }
  }

  // 学习用时情况
  .course-use-time-box {
    margin-top: 10px;

    .content-box {
      height: 350px !important;
      background-position: bottom !important;

      .cylinder-box {
        .number {
          color: #fff;
          font-size: 18px;
          margin-bottom: 5px;
        }

        .cylinder {
          width: 90px;
          height: 200px;
          //border-top-right-radius: 10px;
          border-top-left-radius: 15px;
          border-bottom-right-radius: 15px;
          //border-bottom-left-radius: 10px;
          background: linear-gradient(45deg, #4F91FF 0%, #4F91FF 90%);
          opacity: 0.9;
        }

        .cylinder1 {
          height: 200px;
          background: linear-gradient(0deg, #4F91FF 0%, #ecc270 90%);
          opacity: 0.9;
        }

        .cylinder2 {
          height: 120px;
          background: linear-gradient(0deg, #4F9ccF 0%, #ca2b1d 90%);
          opacity: 0.9;
        }

        .cylinder3 {
          height: 100px;
          background: linear-gradient(0deg, #4F91FF 0%, #e19ff4 90%);
          opacity: 0.9;
        }

        .title {
          margin-top: 5px;
          color: #fff;
          font-size: 16px
        }
      }

      .cylinder-box1 {

      }

      .cylinder-box2 {
        padding-top: 80px;
      }

      .cylinder-box3 {
        padding-top: 100px;
      }
    }
  }

  // 学习行为数据
  .study-action-box {
    .content-box {
      height: 400px !important;
    }

    .number-box {
      margin-top: 30px;
      width: 300px;
      //background-image: url('../../assets/dashboard/study_action_number_background.png');
      //background-position: bottom;
      padding: 10px 10px;

      .number-li {
        margin-right: 20px;

        .number {
          color: #bbe4fc;
          padding-left: 30px;
          margin-bottom: 5px;
        }

        .title {

          color: #5c89e7;
        }

        .square {
          width: 16px;
          height: 16px;
          margin-right: 15px;
        }

        &:nth-child(1) {
          padding-left: 20px;

          .square {
            background-color: #66d6b6;
          }
        }

        &:nth-child(2) {
          padding-left: 10px;

          .square {
            background-color: #ed9bf9;
          }
        }

        &:nth-child(3) {
          padding-left: 5px;

          .square {
            background-color: #4b98f7;
          }
        }
      }
    }

    .chart-box {
      margin-top: 10px;
      width: 480px;
      height: 380px;
    }
  }

  // 优良占比数据
  .score-chart-box {
    margin-top: 10px;

    .content-box {
      height: 350px !important;
      background-position: bottom !important;
    }

    .chart-box {
      width: 470px;
      height: 300px;
    }
  }

  // 日期选择框
  .right-date-box {
    margin-top: 40px;
    width: 520px;

    .left-box {
      background-color: #132559;
      color: #0ac8ff;
      font-size: 24px;
      width: 140px;
      height: 70px;
      line-height: 72px;
      text-align: center;
    }

    .right-box {
      width: 360px;

      .btn-box {
        width: 340px;

        .btn {
          cursor: pointer;
          width: 96px;
          height: 30px;
          line-height: 32px;
          color: #71fffd;
          font-size: 13px;
          background-image: url('../../assets/dashboard/date_box_btn.png');
          background-position: center;
          text-align: center;

          &.active, &:hover {
            background-image: url('../../assets/dashboard/date_box_btn_active.png');
          }
        }
      }

      .select-box {
        position: relative;
        margin-top: 10px;
        background-image: url('../../assets/dashboard/date_box_select_background.png');
        width: 340px;
        padding: 0px 0px 0px 20px;
        height: 40px;
        line-height: 40px;

        img.icon {
          width: 26px;
          height: 25px;
        }

        span.start-date, span.end-date {
          cursor: pointer;
          font-size: 16px;
          color: #0ac8ff;
          width: 100px;
          height: 40px;
          text-align: center;
        }

        span.end-date {
          margin-right: 15px;
        }

        span.text {
          font-size: 20px;
          color: #f2f6ff;
          width: 30px;
          height: 40px;
          text-align: center;
        }

        .date_picker {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 340px;
          z-index: -1;
        }
      }
    }
  }

  // 中间内容
  .center-content {
    position: relative;

    div.title-banner {
      position: absolute;
      top: -460px;
      left: 50%;
      transform: translateX(-50%);
      width: 276px;
      height: 99px;

      img.title-banner {
        width: 276px;
        height: 99px;
        position: absolute;
        z-index: 0;
        left: 0px;
        right: 0px;
      }

      span.text {
        z-index: 1;
        width: 276px;
        height: 99px;
        line-height: 66px;
        position: absolute;
        left: 0px;
        right: 0px;
        font-size: 30px;
        text-align: center;
      }
    }

    div.class-box {
      position: absolute;
      top: -350px;
      left: 50%;
      transform: translateX(-50%);
      width: 384px;
      height: 129px;
      background-image: url('../../assets/dashboard/class_background.png');
      background-position: center;

      .text {
        width: 300px;
        position: absolute;
        top: 40px;
        left: 45px;
        //background-color: red;
        height: 50px;
        line-height: 50px;
        font-weight: bolder;
        font-size: 17px;
      }

      img {
        width: 24px;
        height: 15px;
        position: absolute;
        right: 60px;
        bottom: 60px;
      }
    }

    .select-input {
      width: 320px;
      position: absolute;
      top: -305px;
      height: 50px;
      transform: translateX(-50%);
      z-index: -1;
    }

    // 数据球
    div.data-qiu-box {
      position: absolute;
      top: 120px;

      div.qiu {
        position: absolute;

        .title {
          font-size: 23px;
          font-family: SimHei;
          color: #FFFFFF;
          text-align: center;
          margin-bottom: 5px;
        }

        .number {
          font-size: 38px;

          text-align: center;

          &:hover {
            font-weight: bolder;
          }
        }
      }

      .qiu-1 {
        top: -320px;
        left: -300px;
        width: 145px;
        height: 145px;
        border-radius: 72.5px;
        opacity: 1;
        background: radial-gradient(ellipse at center, rgba(0, 240, 255, 0.1) 60%, #6eecfc 80%);
        border: 1px solid rgba(0, 240, 255, 0.3);

        .title {
          width: 100px;
        }

        .number {
          color: #6eecfc;
        }
      }

      .qiu-2 {
        top: -300px;
        left: 150px;
        width: 128px;
        height: 128px;
        border-radius: 64px;
        opacity: 1;
        background: radial-gradient(ellipse at center, rgba(0, 240, 255, 0.1) 60%, #ed9bf9 80%);
        border: 1px solid rgba(0, 240, 255, 0.3);

        .title {
          font-size: 21px !important;
        }

        .number {
          color: #ed9bf9;
        }
      }

      .qiu-3 {
        top: -130px;
        left: -320px;
        width: 138px;
        height: 138px;
        border-radius: 69px;
        opacity: 1;
        background: radial-gradient(ellipse at center, rgba(0, 240, 255, 0.1) 60%, #f4c061 80%);
        border: 1px solid rgba(0, 240, 255, 0.3);

        .title {
          width: 100px;
        }

        .number {
          color: #f4c061;
        }
      }

      .qiu-4 {
        top: -150px;
        left: 180px;
        width: 128px;
        height: 128px;
        border-radius: 64px;
        opacity: 1;
        background: radial-gradient(ellipse at center, rgba(0, 240, 255, 0.1) 60%, #85fbe4 80%);
        border: 1px solid rgba(0, 240, 255, 0.3);

        .title {
          font-size: 21px !important;
        }

        .number {
          color: #85fbe4;
        }
      }
    }

    // 精灵
    .sprite-box {
      position: absolute;
      top: -150px;
      left: 50%;
      transform: translateX(-50%);
      width: 342px;
      height: 451px;
      background-image: url('../../assets/dashboard/index_sprite.png');
      background-position: center;

    }

    // 去班级详情按钮
    div.go-class-box {
      position: absolute;
      top: 150px;
      left: 60px;


      img {
        width: 306px;
        height: 95px;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0
      }

      span.text {
        position: absolute;
        width: 200px;
        z-index: 1;
        left: 90px;
        top: 38px;
        color: #fff;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }
      }
    }

    // 技术支持单位
    div.company-box {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      width: 350px;
      height: 17px;
      font-size: 18px;
      font-family: SimHei;
      color: #50A2B0;
      line-height: 28px;
      background: radial-gradient(NaNdeg, #209CFF 0%, #68E0CF 0%);
      -webkit-background-clip: text;
    }
  }
}
</style>
